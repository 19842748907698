exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-download-tsx": () => import("./../../../src/pages/app_download.tsx" /* webpackChunkName: "component---src-pages-app-download-tsx" */),
  "component---src-pages-companies-tsx": () => import("./../../../src/pages/companies.tsx" /* webpackChunkName: "component---src-pages-companies-tsx" */),
  "component---src-pages-customer-term-tsx": () => import("./../../../src/pages/customer_term.tsx" /* webpackChunkName: "component---src-pages-customer-term-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-nmn-tsx": () => import("./../../../src/pages/nmn.tsx" /* webpackChunkName: "component---src-pages-nmn-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-structure-tsx": () => import("./../../../src/pages/structure.tsx" /* webpackChunkName: "component---src-pages-structure-tsx" */)
}

