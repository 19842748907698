import '@components/style/spectre.scss';
import '@components/style/reset.css';
import '@components/style/app-common.scss';

export const onClientEntry = () => {
  var webViewVal = /is-web-view=true/;
  if (document.cookie.match(webViewVal)) {
    var body_element = document.getElementsByTagName('body');
    body_element[0].classList.add("is-web-view");
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (!location.hash) window.scrollTo(0, 0)

  return false
}
